import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';
import UnlimitedNow from './UnlimitedNow';
import img_elImage from './images/StartScreen_elImage_90045.jpg';
import btn_icon_743292 from './images/btn_icon_743292.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class StartScreen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
    this._elList_endMarker.scrollIntoView({ behavior: 'smooth' });
    {
      let dataSheet = this.context.appActions.dataSheets['sheet1'];
      let serviceOptions = this.context.appActions.serviceOptions_sheet1;
      if ( !this.context.appActions.dataSheetLoaded['sheet1']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("", this.context.appActions.dataSlots);
        this.context.appActions.loadData_jsonsrc1(dataSheet, serviceOptions, true);
        this.context.appActions.dataSheetLoaded['sheet1'] = true;
      }
      this._dataSheetLoadTimer_sheet1 = setInterval(() => {  // Reload data regularly
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("", this.context.appActions.dataSlots);
        this.context.appActions.loadData_jsonsrc1(dataSheet, serviceOptions, false);
      }, 10000);
    }
  }

  componentWillUnmount() {
    clearInterval(this._dataSheetLoadTimer_sheet1);
  }

  componentDidUpdate(prevProps, prevState) {
    this._elList_endMarker.scrollIntoView({ behavior: 'smooth' });
  }

  
  onClick_elButton2 = async () => {
    // Go to screen 'Screen 4'
    this.context.appActions.goToScreen('screen4', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elText = async () => {
    // Go to screen 'Login 1'
    this.context.appActions.goToScreen('login1', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    // eslint-disable-next-line
    const dataSheet_sheet1 = this.context.dataSheets['sheet1'];
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#f6f6f6',
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    items_list = items_list.concat(this.context.appActions.getDataSheet('sheet1').items);
    this._elList_items = [];
    
    const style_elList = {
      height: 'auto',  // This element is in scroll flow
     };
    const style_elImage = {
      backgroundImage: 'url('+img_elImage+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      boxShadow: '9.1px -7.0px 27px rgba(0, 0, 0, 0.1600)',
     };
    
    const style_elButton2 = {
      display: 'block',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.8500)',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elText = {
      color: 'rgba(0, 0, 0, 0.8500)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elFab = {
      display: 'block',
      color: '#fff',
      textAlign: 'center',
      backgroundColor: 'rgba(0, 0, 0, 0.8500)',
     };
    
    return (
      <div className="AppScreen StartScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="hasNestedComps elList">
            <div style={style_elList}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <UnlimitedNow dataSheetId={'sheet1'} dataSheetRow={row} {...{ 'Title': row['Title'], 'Alias': row['Alias'], }} ref={(el) => {if (el) this._elList_items.push(el)}} />;
                return (<div key={row.key || index}>
                    {itemComp}
                  </div>);
              })}
              <div className="marker" ref={(el)=> this._elList_endMarker = el} />
            </div>
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <div className="elImage" style={style_elImage} />
            <Button className="actionFont elButton2" style={style_elButton2} onClick={this.onClick_elButton2} >
              {this.context.locStrings.start_button2_54548}
            </Button>
            <div className="baseFont elText" style={style_elText} onClick={this.onClick_elText} >
              <div>{this.context.locStrings.start_text_955470}</div>
            </div>
            <Button className="actionFont elFab" style={style_elFab}  variant="fab" >
              <img src={btn_icon_743292} alt="" style={{width: '50.000%', marginTop: '25.000%'}} />
            </Button>
          </div>
        </div>
      </div>
    )
  }
  
}
