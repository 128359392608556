import React, { Component } from 'react';
import './App.css';
import ScreenContext from './ScreenContext';

// UI framework component imports
import Input from 'muicss/lib/react/input';
import Button from 'muicss/lib/react/button';

export default class Login1Screen extends Component {

  static contextType = ScreenContext;


  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
    {
      let dataSheet = this.context.appActions.dataSheets['user'];
      let serviceOptions = this.context.appActions.serviceOptions_user;
      if ( !this.context.appActions.dataSheetLoaded['user']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("/user", this.context.appActions.dataSlots);
        this.context.appActions.loadData_xano1(dataSheet, serviceOptions, true);
        this.context.appActions.dataSheetLoaded['user'] = true;
      }
      this._dataSheetLoadTimer_user = setInterval(() => {  // Reload data regularly
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("/user", this.context.appActions.dataSlots);
        this.context.appActions.loadData_xano1(dataSheet, serviceOptions, false);
      }, 10000);
    }
  }

  componentWillUnmount() {
    clearInterval(this._dataSheetLoadTimer_user);
  }

  componentDidUpdate(prevProps, prevState) {
  }

  textInputChanged_elField = (event) => {
    this.setState({field: event.target.value});
    
    this.context.appActions.updateDataSlot("ds_logingate", event.target.value);
  }
  
  getValue_elField = () => {
    return this.state.field || '';
  }
  
  onClick_elButton = async () => {
    // Go to screen 'Screen 4'
    this.context.appActions.goToScreen('screen4', this.context.baseScreenId, { transitionId: 'fadeIn' });
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.context.transitionId && this.context.transitionId.length > 0 && this.context.atTopOfScreenStack && this.context.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.context.transitionId;
    }
    if ( !this.context.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      boxSizing: 'border-box',
      backgroundColor: '#f6f6f6',
     };
    
    const style_elField = {
      display: 'block',
      paddingTop: 0,
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_elButton = {
      display: 'block',
      color: '(null)',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="AppScreen Login1Screen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elField">
            <Input className="baseFont" style={style_elField} type="password" placeholder={this.context.locStrings.login1_field_139536} onChange={this.textInputChanged_elField} value={this.getValue_elField()}  />
          </div>
          
          <div className="elButton">
            <Button className="actionFont" style={style_elButton}  color="accent" onClick={this.onClick_elButton} >
              {this.context.locStrings.login1_button_40754}
            </Button>
          </div>
        </div>
        
      </div>
    )
  }
  
}
