import './mui.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { StrictMode } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import App2 from "./App2";


ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();



const rootElement = document.getElementById("root2");
ReactDOM.render(
  <StrictMode>
      <Auth0Provider
        domain="unlimitednow.us.auth0.com"
        clientId="Oc8jfabnhjXLm67oMuF9UlyNHzbUW31s"
        redirectUri={window.location.origin}
      >
        <App2 />
      </Auth0Provider>
  </StrictMode>,
  rootElement
);
